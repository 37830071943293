import React, { Suspense } from "react";
import { loadUserData } from "../../../Helpers";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { openLoginModal } from "../../../store/modal/actions";
import europAssitence from "../../../assets/static/landing-host/people.webp";
import homeMobile1 from "../../../assets/static/landing-host/mobile4.webp";
import downloadAppMobile from "../../../assets/static/Promos/Slide_1_Banner_APP.webp";
import europAssitenceMobile from "../../../assets/static/Promos/Slide_3_Banner_EUROP-ASS.webp";
import { useDispatch } from "react-redux";
import ImageLazyLoad from "../../ui/image-lazyLoad";
const SwiperPromotions = ({ codeDiscount }) => {
  SwiperCore.use([Autoplay]);

  const router = useHistory();
  const userData = loadUserData();

  const dispatch = useDispatch();

  return (
    <Suspense fallback={<></>}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        preloadImages={1}
        lazy={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="relative w-full h-full swiperHome"
      >
        <SwiperSlide>
          <>
            <section className="relative hidden sm:block">
           

              <ImageLazyLoad
                src={homeMobile1}
                alt="download app"
                className="relative object-cover w-full h-full"
              />
              {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.aloharApp&pcampaignid=web_share"
              className={"h-[50%]  absolute right-[32vw]  w-[30vw] bottom-[0]"}
            ></a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/ar/app/aloharme/id1663574787"
              className={"h-[50%]  absolute right-0  w-[30vw] bottom-[0]"}
            ></a> */}
            </section>
            <div
              className={`z-0 bg-hertzMobile bg-center bg-cover  sm:py-[26%]  sm:hidden h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
            >
              <button
                aria-label="Aplicar descuento"
                onClick={
                  userData
                    ? () => {
                        codeDiscount();
                      }
                    : () => {
                        router.push("/login");
                      }
                }
                className="absolute bottom-0 left-0 w-full h-2/3 "
              ></button>
            </div>
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <ImageLazyLoad
              src={downloadAppMobile}
              classNameContent={"w-full h-full"}
              alt="DownLoad app"
              className={`z-0 bg-downloadAppMobile relative bg-center sm:hidden  bg-cover  sm:py-[26%]  h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
            />
            <ImageLazyLoad
              src={europAssitence}
              className="hidden object-cover sm:block"
              alt="EuropAssistence"
            />
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <div
              className={` py-[26%] hidden   items-center overflow-hidden  h-full w-full  relative sm:flex flex-col justify-center`}
            >
              <span className="absolute top-0 right-0 z-0 w-full h-full bg-center bg-no-repeat bg-contain bg-hertz"></span>

              <button
                aria-label="25% de descuento en el alquiler de tu vehículo."
                onClick={
                  userData ? codeDiscount : () => dispatch(openLoginModal())
                }
                className=" text-[2vw] sm:text-[1.3vw] RobotoBold text-center text-white absolute w-full flex justify-center items-center left-[50%] translate-x-[-50%] bottom-[12%]"
              >
                25% de descuento en el alquiler de tu vehículo. <br />
                ¡Hacé click y obtené tu código!
              </button>
            </div>
            <ImageLazyLoad
              src={europAssitenceMobile}
              classNameContent={"w-full h-full"}
              alt="Europ assitence"
              className={`z-0 bg-europMobile sm:hidden  bg-center bg-cover  sm:py-[26%]  h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
            />
          </>
        </SwiperSlide>
      </Swiper>
    </Suspense>
  );
};

export default SwiperPromotions;
