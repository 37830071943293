import React, { useState } from "react";
import MaskedInput from "react-maskedinput";
import ErrorMessage from "../Alerts/ErrorAlert";
import RadioMobile from "../AloharForm/Utils/RadioMobile";
import CheckboxRadio from "../AloharForm/Utils/CheckboxRadio";

export const TarjetInfoMobile = ({
  cardNumberSize,
  cardNumberMask,
  handleCardNumberChange,
  handleInputFocus,
  errors,
  handleInputChange,
  handleOnBlur,
  cardCVCMask,
  placeholderCVC,
  cuitMask,
  couponMessage,
  radioForeign,
  handleOnChangeForeign
}) => {

  return (
    <>
      <p className="Checkout__form__title">Información de la Tarjeta</p>
      <div className="Checkout__form__inputContainer">
        <label
          className="Checkout__form__inputContainer__label"
          htmlFor="cardNumber"
        >
          Número de la Tarjeta
        </label>
        <MaskedInput
          id="cardNumber"
          className="Checkout__form__inputContainer__inputm"
          mask={cardNumberMask}
          name="cardNumber"
          type="tel"
          size={cardNumberSize}
          placeholder="Escribe el número de tu tarjeta"
          onChange={handleCardNumberChange}
          onFocus={handleInputFocus}
          onBlur={handleCardNumberChange}
          onPaste={handleCardNumberChange}
          onKeyDown={handleCardNumberChange}
        />
        {errors["cardNumber"] ? (
          <ErrorMessage message={errors["cardNumber"]} />
        ) : (
          ""
        )}
      </div>
      <div className="Checkout__form__inputContainer">
        <label
          className="Checkout__form__inputContainer__label"
          htmlFor="cardName"
        >
          Titular de la Tarjeta
        </label>
        <input
          className="Checkout__form__inputContainer__inputm"
          name="cardName"
          id="cardName"
          type="text"
          placeholder="Escribe tu nombre como aparece en tu tarjeta"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleOnBlur}
        />
        {errors["cardName"] ? (
          <ErrorMessage message={errors["cardName"]} />
        ) : (
          ""
        )}
      </div>
      <div className="Checkout__form__two">
        <div className="Checkout__form__inputContainer">
          <label
            className="Checkout__form__inputContainer__label"
            htmlFor="cardExpiry"
          >
            Vencimiento
          </label>
          <MaskedInput
            mask="11/11"
            className="Checkout__form__inputContainer__inputm"
            name="cardExpiry"
            id="cardExpiry"
            type="text"
            placeholder="MM/AA"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleOnBlur}
          />
          {errors["cardExpiry"] ? (
            <ErrorMessage message={errors["cardExpiry"]} />
          ) : (
            ""
          )}
        </div>
        <div className="Checkout__form__inputContainer">
          <label
            className="Checkout__form__inputContainer__label"
            htmlFor="cardCVC"
          >
            CVC
          </label>
          <MaskedInput
            mask={cardCVCMask}
            className="Checkout__form__inputContainer__inputm"
            name="cardCVC"
            id="cardCVC"
            type="text"
            placeholder={placeholderCVC}
            // onChange={this.handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleOnBlur}
          />
          {errors["cardCVC"] ? (
            <ErrorMessage message={errors["cardCVC"]} />
          ) : (
            ""
          )}
        </div>
      </div>

        <RadioMobile
          key={"radioValue"}
          id={"stranger"}
          value={"stranger"}
          name={"stranger"}
          title={"Soy extranjero"}
          customClickEvent={handleOnChangeForeign}
          blackText={true}
          checked={radioForeign}
          disabled={false}
        />
      <div className="Checkout__form__inputContainer">
        <label className="Checkout__form__inputContainer__label" htmlFor="cuit">
          {radioForeign ? "Documento" : "CUIT / CUIL"}
        </label>
        {radioForeign ? (
          <>
            <input
              mask={cuitMask}
              className="Checkout__form__inputContainer__inputm"
              name="cuit"
              id="cuit"
              type={"text"}
              placeholder="Documento"
              // onChange={this.handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleOnBlur}
            />
            {errors["cuit"] ? <ErrorMessage message={errors["cuit"]} /> : ""}
          </>
        ) : (
          <>
            <MaskedInput
              mask={cuitMask}
              className="Checkout__form__inputContainer__inputm"
              name="cuit"
              id="cuit"
              type="text"
              placeholder="CUIT/CUIL"
              // onChange={this.handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleOnBlur}
            />
            {errors["cuit"] ? <ErrorMessage message={errors["cuit"]} /> : ""}
          </>
        )}
      </div>




      <div className="Checkout__form__inputContainer">
        <label className="Checkout__form__inputContainer__label" htmlFor="cuit">
          Dirección de Facturación
        </label>
        <input
          className="Checkout__form__inputContainer__inputm"
          name="billingAddress"
          id="billingAddress"
          type="text"
          placeholder="Dirección  "
          // onChange={this.handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleOnBlur}
        />
        {errors["billingAddress"] ? (
          <ErrorMessage message={errors["billingAddress"]} />
        ) : (
          ""
        )}
      </div>
      <div className="Checkout__form__inputContainer">
        <label
          className="Checkout__form__inputContainer__label"
          htmlFor="couponCode"
        >
          Código de Cupón
        </label>
        <input
          className="Checkout__form__inputContainer__inputm"
          name="couponCode"
          id="couponCode"
          type="text"
          placeholder="Código del cupón"
          // onChange={this.handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleOnBlur}
        />
        {couponMessage !== "" ? (
          <ErrorMessage message={couponMessage} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};