import React from 'react';
import '../../assets/styles/LandingPage/_card_info.sass';

const CardInfo = ({icono, titulo,texto}) => {
    return(
        <div className="card_info">
            <div className="card_inf_holder">
                <img alt="icono" src={icono}/>
            </div>
            <div className="card_info_holder">
                <div className="titulo_info">{titulo}</div>
                <div className="texto_info" dangerouslySetInnerHTML={{ __html: texto }} ></div>
            </div>
        </div>
    );
}

export default CardInfo;


 